import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8febaad0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-prepend" }
const _hoisted_2 = { class: "lh-20 distinct--text mt-2px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_emoji = _resolveComponent("tm-emoji")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_tm_form_line, {
      label: "Name",
      class: "mb-4"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_field, {
          modelValue: _ctx.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
          validators: "required"
        }, {
          prepend: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_tm_emoji, { name: "rocket" })
            ])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _renderSlot(_ctx.$slots, "before-description", {}, undefined, true),
    _createVNode(_component_tm_form_line, { label: "Description" }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_field, {
          modelValue: _ctx.description,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.description) = $event)),
          type: "textarea"
        }, null, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.descriptionHint), 1)
      ]),
      _: 1
    })
  ]))
}