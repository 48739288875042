
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmEmoji from '@/components/shared/TmEmoji.vue'

export default defineComponent({
  components: {
    TmFormLine,
    TmEmoji,
  },
  props: {
    initName: {
      type: String,
      default: 'Marketing',
    },
    initDescription: {
      type: String,
      default: 'Visualize campaign progress and milestones.',
    },
    descriptionHint: {
      type: String,
    },
  },
  setup(props) {
    const name = ref(props.initName)
    const description = ref(props.initDescription)

    return {
      name,
      description,
    }
  },
})
